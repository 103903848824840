<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
        ref="onboardingLayout"
    >
        <h3>Welcome!</h3>
        <h5 class="fw-light">
            Just a little about you to create your exclusive invite code.
        </h5>
        <form-container
            id="applicationForm"
            ref="basicInfoForm"
            @on-submit="onSubmit"
            @onError="onError"
        >
            <section-header class="mt-3">
                Your Information
            </section-header>
            <div class="row g-2 mb-2">
                <div class="col">
                    <form-field
                        v-model="agentFirstName"
                        name="agentFirstName"
                        :placeholder="$t('components.applicationForm.placeholder.firstName')"
                        :label="$t('components.applicationForm.placeholder.firstName')"
                        validation-rules="required|min:2"
                        autocomplete="given-name"
                    />
                </div>
                <div class="col">
                    <form-field
                        v-model="agentLastName"
                        name="agentLastName"
                        :placeholder="$t('components.applicationForm.placeholder.lastName')"
                        :label="$t('components.applicationForm.placeholder.lastName')"
                        validation-rules="required|min:2"
                        autocomplete="family-name"
                    />
                </div>
            </div>
            <form-field
                class="mb-2"
                v-model="agentEmail"
                name="agentEmail"
                :placeholder="$t('components.applicationForm.placeholder.email')"
                :label="$t('components.applicationForm.placeholder.email')"
                validation-rules="required|email"
                input-type="email"
                inputmode="email"
            />
            <section-header class="mt-4">
                Real Estate Information
            </section-header>
            <form-field
                class="mb-2"
                v-model="agentLicenseNumber"
                name="agentLicenseNumber"
                placeholder="License Number"
                label="License Number"
                validation-rules="required"
            />
            <form-field
                class="mb-2"
                v-model="agentWebsite"
                name="agentWebsite"
                placeholder="Website"
                label="Website"
                validation-rules="required"
            />
            <form-button
                class="mt-4"
                label="Get Invite Code"
                :submitting="submitting"
                type="submit"
                event-name="click_button_submit_agent_info"
            />
        </form-container>
        <template #sidebar>
            <agent-why-list-item />
            <help-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormContainer from '@/components/base/FormContainer'
    import SectionHeader from '@/components/SectionHeader'
    import FormField from '@/components/base/FormField'
    import FormButton from '@/components/base/FormButton'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import AgentWhyListItem from '@/components/onboarding/AgentWhyListItem'
    import { getNextRoute } from '@/flow/flowController'
    import { postUpdateRealEstateLeadFields } from '@/services/realEstateAgent'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    export default {
        name: 'AgentInfo',
        components: {
            AgentWhyListItem,
            HelpListItem,
            FormButton,
            FormField,
            SectionHeader,
            OnboardingLayout,
            FormContainer,
        },
        data: function () {
            return {
                submitting: false,
                loading: false,
                loadingTitle: '',
                errorText: '',
                agentFirstName: '',
                agentLastName: '',
                agentEmail: '',
                agentLicenseNumber: '',
                agentWebsite: '',
            }
        },
        mounted: function () {
            this.$logEvent('view_agent_ask_info')
        },
        methods: {
            onSubmit: async function () {
                this.$logEvent('click_button_submit_agent_info')
                this.submitting = true

                const response = await postUpdateRealEstateLeadFields({
                    firstName: this.agentFirstName,
                    lastName: this.agentLastName,
                    email: this.agentEmail,
                    licenseNumber: this.agentLicenseNumber,
                    website: this.agentWebsite,
                })

                if (response.data.success) {
                    this.submitting = false

                    appSessionStorage.setItem(sessionStorageKey.agentPifInviteCode, response.data.payload.inviteCode)
                    appSessionStorage.setItem(sessionStorageKey.agentPifShareLink, response.data.payload.shareLink)

                    return await this.$router.replace(getNextRoute(this.$router))
                } else {
                    this.errorText = 'Something went wrong. Please refresh the page or close your tab and try again.'
                }
            },
            onError: function () {
                this.errorText = 'Something went wrong. Please refresh the page or close your tab and try again.'
            },
        },
    }
</script>

<style scoped></style>
