<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/icon-verify-id.svg"
                alt="ID Verification"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                Why is my license number needed?
            </p>
            <p class="text-muted">
                We offer this program to agents whose licenses are active and have always been in good standing
            </p>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'AgentWhyListItem',
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
