<template>
    <onboarding-layout>
        <div class="pif-background text-center useLoraFont mt-2">
            <img
                src="@/assets/images/origination/heart-outline.svg"
                width="43"
                class="mb-2"
                alt="love"
            >
            <div class="pif-title mb-2">
                Congrats, you have access!
            </div>
            <p class="mb-2">
                Share your exclusive invite code and give your clients the magical Aven Card experience. Plus we will gift them <strong>$250</strong> in credit!<sup>*</sup>
            </p>
            <div class="wrap">
                <img
                    src="@/assets/images/origination/CardSharetheLove.svg"
                    width="173"
                    class="mb-2 center-in-wrap"
                    alt="aven card"
                >
                <p class="aven-pif-card-amount">
                    $250
                </p>
            </div>
            <div class="d-grid mt-3">
                <button
                    class="btn btn-outline-primary rounded-pill"
                    @click="shareViaText"
                >
                    Text Your Clients Now
                </button>
            </div>
            <div class="d-flex flex-row justify-content-center align-items-center">
                <hr class="bg-primary w-100">
                <div class="p-2 fw-normal">
                    or
                </div>
                <hr class="bg-primary w-100">
            </div>
            <div class="d-grid">
                <p>Share your code. Tap to copy</p>
                <div
                    class="btn-link-copy"
                    @click="copyLink"
                >
                    {{ copyButtonDisplayString }}
                </div>
            </div>
        </div>
        <p class="text-muted small mt-3">
            For more details, see Pay It Forward Terms:
        </p>
        <p class="text-muted small">
            <sup>*</sup>Payment of the signing bonus to the Recipient is contingent upon the final acceptance of the deed of trust by the applicable recorder’s office and the activation of the
            Recipient’s Aven Card. The signing bonus will be issued to the Recipient in the form of a statement credit within [5] business days of the activation of their Aven Card.
        </p>
        <p class="text-muted small">
            Subject to validation of an active real estate license, clearance of federal and state record checks and no new clients being flagged for fraud.
        </p>
    </onboarding-layout>
</template>

<script>
    import format from '@/mixins/format'
    import OnboardingLayout from '@/layouts/Onboarding'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { isIOS } from '@/utils/parseUserAgents'

    export default {
        name: 'AgentPifOffer',
        components: { OnboardingLayout },
        mixins: [format],
        data: function () {
            return {
                payItForwardLink: '',
                payItForwardCode: '',
                payItForwardMaxAmount: 250,
                isLinkCopied: false,
                isIOS: isIOS(),
            }
        },
        mounted: function () {
            this.payItForwardCode = appSessionStorage.getItem(sessionStorageKey.agentPifInviteCode)
            this.payItForwardLink = appSessionStorage.getItem(sessionStorageKey.agentPifShareLink)
            this.$logEvent('view_agent_pif_offer', { pifCode: this.payItForwardCode })
        },
        computed: {
            copyButtonDisplayString: function () {
                if (this.isLinkCopied) {
                    return 'Copied!'
                } else {
                    return this.payItForwardCode
                }
            },
        },
        methods: {
            copyLink: function () {
                this.copyToClipboard(this.payItForwardLink)
            },
            copyToClipboard: function (str) {
                const el = document.createElement('textarea')
                el.value = str
                document.body?.appendChild(el)
                el.select()
                document.execCommand('copy')
                document.body?.removeChild(el)
                this.isLinkCopied = true
                setTimeout(() => (this.isLinkCopied = false), 2000)
                window.logEvent('click_button_agent_pif_copy_link', { pifLink: this.payItForwardLink, pifAmount: this.payItForwardMaxAmount })
            },
            shareViaText: function () {
                const smsMessage = `You’ve been invited to the magical Aven card experience. You are eligible for a $250 gift after joining via the link. ${this.payItForwardCode}` //sms max length per message is 160, current string max length is 150
                this.$logEvent('click_button_agent_pif_share_via_text_message', { message: smsMessage, pifAmount: this.payItForwardMaxAmount })
                window.location.href = this.isIOS ? encodeURI(`sms://&body=${smsMessage}`) : `sms:?body=${encodeURI(smsMessage)}`
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/variables';

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-Bold.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lora';
        src: url('~@/assets/fonts/Lora-BoldItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
    }

    .useLoraFont {
        font-family: 'Lora';
        font-weight: bold;

        p {
            font-weight: normal;
        }
    }

    .pif-background {
        border-radius: 16px;
        padding: 2rem 1.5rem 2rem 1.5rem;
        background-color: #fadfce;
        border-color: #fadfce;
        justify-content: center;
    }

    .pif-title {
        font-size: 1.25rem;
        font-style: italic;
    }

    .btn-send-sms {
        height: 54px;
        width: 100%;
        font-family: 'Lora', serif;
        font-style: italic;
        font-size: 1rem;
        border-width: 1px;
        border-color: $black;
        background-color: #fadfce;
        border-radius: 27px;
    }

    .wrap {
        width: 100%;
        height: 110px;
        color: white;
        text-align: center;
        line-height: 140px;
        position: relative;
    }
    .center-in-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .aven-pif-card-amount {
        position: absolute;
        color: #c99134;
        font-weight: bold;
        top: 2px;
        left: 50%;
        transform: translate(36px, 0);
        font-family: 'gtamerica';
    }
    .btn-link-copy {
        cursor: pointer;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 8px;
        align-content: center;
        background-color: $white;
        font-weight: normal;
        font-color: $black;
        border-width: 0;
    }
</style>
