import { httpClient } from '@/utils/http-client'

interface RealEstateAgentLead {
    firstName: string
    lastName: string
    email: string
    licenceNumber: string
    website: string
}

const postUpdateRealEstateLeadFields = async (payload: RealEstateAgentLead) => {
    return await httpClient.post('/real_estate_agent/update_lead_fields', payload)
}

export { postUpdateRealEstateLeadFields }
